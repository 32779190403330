document.addEventListener("DOMContentLoaded", function() {
    document.querySelectorAll(".scroll-to").forEach(function(element) {
        element.addEventListener("click", function(event) {
            event.preventDefault();
            var targetId = this.getAttribute('href').substring(1);
            var targetElement = document.getElementById(targetId);
            var offset = targetElement.getBoundingClientRect().top + window.scrollY - 15;
            window.scrollTo({ top: offset, behavior: 'smooth' });
        });
    });

    if (window.innerWidth < 992) {
        document.querySelector(".navbar-toggler").addEventListener("click", function() {
            if (!this.classList.contains('collapsed')) {
                document.querySelector(".navbar-expand-lg").style.position = 'absolute';
                window.scrollTo({ top: 0, behavior: 'smooth' });
            } else {
                document.querySelector(".navbar-expand-lg").style.position = 'fixed';
            }
        });

        document.querySelectorAll(".navbar-nav .nav-link.level-1").forEach(function(link) {
            link.addEventListener("click", function(event) {
                if (!this.parentElement.classList.contains('open') && this.parentElement.classList.contains('dropdown') && !this.classList.contains('search')) {
                    event.preventDefault();
                    this.parentElement.querySelector('.dropdown-menu.level-2').classList.add('show');
                    document.querySelectorAll('.navbar-nav .nav-item.level-1').forEach(function(item) {
                        item.classList.add('hidden');
                    });
                    this.classList.add('open');
                    this.parentElement.classList.add('open');
                    this.parentElement.classList.remove('hidden');
                    document.querySelector('.nav-back').classList.add('level2');
                }
            });
        });

        document.querySelectorAll(".navbar-nav .nav-link.level-2").forEach(function(link) {
            link.addEventListener("click", function(event) {
                if (!this.parentElement.classList.contains('open') && this.parentElement.classList.contains('dropdown')) {
                    event.preventDefault();
                    this.parentElement.querySelector('.dropdown-menu.level-3').classList.add('show');
                    document.querySelectorAll('.navbar-nav .nav-link.level-1').forEach(function(item) {
                        item.classList.add('hidden');
                    });
                    document.querySelectorAll('.navbar-nav .nav-item.level-2').forEach(function(item) {
                        item.classList.add('hidden');
                    });
                    this.classList.add('open');
                    this.parentElement.classList.add('open');
                    this.parentElement.classList.remove('hidden');
                    document.querySelector('.nav-back').classList.remove('level2');
                    document.querySelector('.nav-back').classList.add('level3');
                }
            });
        });

        document.querySelector(".nav-back").addEventListener("click", function(event) {
            if (this.classList.contains('level3')) {
                document.querySelector('.dropdown-menu.level-3').classList.remove('show');
                document.querySelectorAll('.navbar-nav .nav-item.level-2').forEach(function(item) {
                    item.classList.remove('hidden');
                });
                document.querySelectorAll('.navbar-nav .nav-item.level-2').forEach(function(item) {
                    item.classList.remove('open');
                });
                document.querySelectorAll('.navbar-nav .nav-link.level-2').forEach(function(item) {
                    item.classList.remove('open');
                });
                document.querySelector('.navbar-nav .nav-item.level-1.open .nav-link.open').classList.remove('hidden');
                this.classList.remove('level3');
                this.classList.add('level2');
            } else if (this.classList.contains('level2')) {
                document.querySelectorAll('.navbar-nav .level-1').forEach(function(item) {
                    item.classList.remove('open');
                    item.classList.remove('hidden');
                });
                document.querySelectorAll('.navbar-nav .level-2').forEach(function(item) {
                    item.classList.remove('show');
                });
                this.classList.remove('level2');
            }
        });
    } else {
        document.querySelectorAll(".navbar-nav .nav-link.level-1").forEach(function(link) {
            link.addEventListener("mouseenter", function() {
                if (!this.parentElement.classList.contains('open')) {
                    document.querySelectorAll('.navbar-nav .nav-item.level-1').forEach(function(item) {
                        item.classList.remove('open');
                    });
                }
            });

            link.addEventListener("click", function(event) {
                if (this.classList.contains('dropdown') && !this.parentElement.classList.contains('open')) {
 if (!this.classList.contains('search')) {
                        event.preventDefault();
                        this.parentElement.classList.add('open');
                    }
                }
            });
        });
    }

    document.querySelectorAll(".accordion .headline").forEach(function(headline) {
        headline.addEventListener("click", function() {
            if (this.classList.contains('image-2')) {
                if (this.classList.contains('open')) {
                    this.classList.remove('open');
                    this.closest('.accordion').querySelector('.more').classList.remove('open');
                } else {
                    this.classList.add('open');
                    this.closest('.accordion').querySelector('.more').classList.add('open');
                }
            } else {
                if (this.classList.contains('open')) {
                    document.querySelectorAll(".accordion .more").forEach(function(more) {
                        more.style.display = 'none';
                    });
                    this.classList.remove('open');
                } else {
                    document.querySelectorAll(".accordion .more").forEach(function(more) {
                        more.style.display = 'none';
                    });
                    document.querySelectorAll(".accordion .headline").forEach(function(headline) {
                        headline.classList.remove('open');
                    });
                    this.closest('.accordion').querySelector('.more').style.display = 'block';
                    this.classList.add('open');
                }
            }
        });
    });

    document.querySelectorAll(".accordion .show-all").forEach(function(button) {
        button.addEventListener("click", function(event) {
            event.preventDefault();
            this.closest('.accordion').querySelectorAll('.headline').forEach(function(headline) {
                headline.classList.add('open');
            });
            this.closest('.accordion').querySelector('.more').style.maxHeight = '2000px';
            this.style.display = 'none';
        });
    });

    document.querySelectorAll(".youtube.content .play-button").forEach(function(button) {
        button.addEventListener("click", function(event) {
            event.preventDefault();
            var height = this.offsetHeight;
            this.parentElement.style.height = height + 'px';
            this.style.display = 'none';
            this.parentElement.querySelector('img').style.display = 'none';
            var iframe = this.parentElement.querySelector('iframe');
            iframe.style.height = height + 'px';
            iframe.style.display = 'block';
            iframe.src += '&autoplay=1';
        });
    });

    document.querySelectorAll(".filter .video a").forEach(function(link) {
        link.addEventListener("click", function(event) {
            event.preventDefault();
            var article = this.querySelector('.article').innerHTML;
            var arr = article.split('/');
            fetch('/' + arr[1] + '/ajax/video-overlay/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 'article': arr[2] })
            })
            .then(response => response.text())
            .then(data => {
                document.getElementById("cycle-lightbox-background").style.display = 'block';
                document.getElementById("cycle-lightbox-content").innerHTML = data;
                document.getElementById("cycle-lightbox").classList.add('dark');
            });
            var top = window.scrollY + 80;
            document.getElementById("cycle-lightbox").style.top = top + 'px';
        });
    });

    document.querySelectorAll(".filter .photos a.cycle-lightbox").forEach(function(link) {
        link.addEventListener("click", function(event) {
            event.preventDefault();
            var article = this.querySelector('.article').innerHTML;
            var arr = article.split('/');
            fetch('/' + arr[1] + '/ajax/photos-overlay/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 'article': arr[2] })
            })
            .then(response => response.text())
            .then(data => {
                document.getElementById("cycle-lightbox-background").style.display = 'block';
                document.getElementById("cycle-lightbox-content").innerHTML = data;
                document.getElementById("cycle-lightbox").classList.add('light');
            });
            var top = window.scrollY + 10;
            document.getElementById("cycle-lightbox-background").style.top = top + 'px';
        });
    });

//     document.getElementById("cycle-lightbox-close").addEventListener("click", function() {
//         document.getElementById("cycle-lightbox-background").style.display = 'none';
//         document.getElementById("cycle-lightbox-content").innerHTML = '';
//         document.getElementById("cycle-lightbox").classList.remove('light', 'dark');
//  });

    var pos = window.scrollY;
    if (pos > 100) {
        document.body.classList.add('scroll');
    }

    if (document.querySelector(".wrapper.config")) {
        if (document.getElementById("akt-konf")) {
            var top = document.querySelector('.article_headline').offsetHeight + 40;
            document.getElementById("akt-konf").style.top = top + 'px';
            fetch("index.php?clang=" + document.getElementById("clang").value + "&schritt=" + document.getElementById("step").value + "&article_id=5252&konfiguration_id=" + document.getElementById("konfiguration_id").value)
                .then(response => response.text())
                .then(data => {
                    document.getElementById("akt-konf").innerHTML = data;
                    if (document.getElementById('akt-konf').offsetHeight > document.querySelector('.wrapper.config').offsetHeight - 100) {
                        document.querySelector('.wrapper.config').style.height = (document.getElementById('akt-konf').offsetHeight + 100) + 'px';
                    }
                });
        }

        document.querySelectorAll(".konf-container").forEach(function(container) {
            container.addEventListener("click", function() {
                if (!this.classList.contains("disabled")) {
                    if (this.classList.contains("single")) {
                        this.querySelector("input:radio").checked = true;
                        fetch("index.php?clang=" + document.getElementById("clang").value + "&schritt=" + document.getElementById("step").value + "&article_id=5252&konfiguration_id=" + document.getElementById("konfiguration_id").value)
                            .then(response => response.text())
                            .then(data => {
                                document.getElementById("akt-konf").innerHTML = data;
                            });
                    }
                }
            });
        });

        if (document.getElementById("no-choice").value == '1') {
            document.getElementById("vor").removeAttribute('disabled');
            document.getElementById("vor").classList.remove("disabled");
            document.getElementById("config").querySelector("#forward").classList.add('active');
            document.getElementById("config").querySelector("#forward").classList.remove('disabled');
            form2.submit();
        }

        document.getElementById("config").addEventListener("click", function(event) {
            if (event.target.matches("#forward.active")) {
                if (document.getElementById("start-config")) {
                    document.getElementById("start-config").click();
                } else {
                    document.getElementById("vor").click();
                }
            }
        });

        document.querySelectorAll('.konf-container .form-control').forEach(function(input) {
            input.addEventListener('keypress', function(event) {
                if (event.keyCode == 13) {
                    event.preventDefault();
                    this.blur();
                }
            });
        });

        document.getElementById("config").addEventListener("click", function(event) {
            if (event.target.matches("#finish.active")) {
                document.getElementById("konf-ende").click();
            }
            if (event.target.matches("#back.active")) {
                document.getElementById("zurueck").click();
            }
        });

        document.getElementById("konf-ende").setAttribute('disabled', 'disabled');
        document.getElementById("konf-ende").classList.add("disabled");
        document.getElementById("vor").setAttribute('disabled', 'disabled');
        document.getElementById("vor").classList.add("disabled");

        document.querySelectorAll(".konf-container input:radio").forEach(function(radio) {
            radio.addEventListener("click", function() {
                if (document.querySelector("input:radio:checked")) {
                    document.getElementById("vor").removeAttribute('disabled');
                    document.getElementById("vor").classList.remove("disabled");
                    document.getElementById("config").querySelector("#forward").classList.add('active');
                    document.getElementById("config").querySelector("#forward").classList.remove('disabled');
                    document.getElementById("konf-ende").removeAttribute('disabled');
                    document.getElementById("konf-ende").classList.remove("disabled");
                }
            });
        });

        if (document.querySelector("input:radio:checked")) {
            document.getElementById("vor").removeAttribute('disabled');
            document.getElementById("vor").classList.remove("disabled");
            document.getElementById("config").querySelector("#forward").classList.add('active');
            document.getElementById("config").querySelector("#forward").classList.remove("disabled");
            document.getElementById("konf-ende").removeAttribute('disabled');
            document.getElementById("konf-ende").classList.remove("disabled");
        }

        if (!document.querySelector("input:radio")) {
            document.getElementById("vor").removeAttribute('disabled');
            document.getElementById("vor").classList.remove("disabled");
            document.getElementById("config").querySelector("#forward").classList.add('active');
            document.getElementById("config").querySelector("#forward").classList.remove("disabled");
            document.getElementById("konf-ende").removeAttribute('disabled');
            document.getElementById("konf-ende").classList.remove("disabled");
        }

        document.querySelectorAll(".konf-container").forEach(function(container) {
            container.addEventListener("click", function() {
                if (!this.classList.contains("disabled")) {
                    this.classList.remove("deselected");
                    if (this.classList.contains("single")) {
                        document.querySelectorAll(".konf-container").forEach(function(item) {
                            item.classList.remove("active");
                        });
                        this.querySelector("input:radio").checked = true;
                        this.classList.add("active");
                        document.getElementById("vor").removeAttribute('disabled');
                        document.getElementById("vor").classList.remove("disabled");
                        document.getElementById("config").querySelector("#forward").classList.add('active');
                        document.getElementById("config").querySelector("#forward").classList.remove("disabled");
                        document.getElementById("konf-ende").removeAttribute('disabled');
                        document.getElementById("konf-ende").classList.remove("disabled");
                    }
                    if (this.classList.contains("multiple")) {
                        var checkbox = this.querySelector("input:checkbox");
                        checkbox.checked = !checkbox.checked;
                        this.classList.toggle("active", checkbox.checked);
                        this.classList.toggle("deselected", !checkbox.checked);
                    }
                    this.addEventListener("mouseout", function() {
                        this.classList.remove("deselected");
                    });
                }
                var count = document.querySelectorAll('.konf-container').length;
                if (count == 1 || this.classList.contains("single")) {
                    if (document.getElementById('konf-ende')) {
                        document.getElementById("cycle-lightbox-background").style.display = 'block';
                        document.getElementById("cycle-lightbox.config").style.top = this.getBoundingClientRect().top + 'px';
                        document.getElementById("cycle-lightbox.config").style.left = this.getBoundingClientRect().left + 'px';
                        document.getElementById("cycle-lightbox.config").style.width = this.offsetWidth + 'px';
                        document.getElementById("cycle-lightbox.config").style.minHeight = this.offsetHeight + 'px';
                    } else {
                        setTimeout(function() { document.getElementById("vor").click(); }, 1500);
                    }
                }
            });
        });

        if (document.getElementById('akt-konf').offsetHeight > document.getElementById('config').offsetHeight) {
            document.getElementById('config').style.height = (document.getElementById('akt-konf').offsetHeight + 25) + 'px';
        }
    }

    // document.getElementById("config-abbrechen").addEventListener("click", function() {
    //     document.getElementById("cycle-lightbox-background").style.display = 'none';
    // });

    // document.getElementById("config-fertigstellen").addEventListener("click", function() {
    //     document.getElementById("konf-ende").click();
    // });

    document.querySelectorAll(".config.filter .row.items .show-more").forEach(function(button) {
        button.addEventListener("click", function() {
            if (this.classList.contains('open')) {
                document.querySelectorAll(".config.filter .row.items .more").forEach(function(more) {
                    more.style.display = 'none';
                });
                this.classList.remove('open');
            } else {
                document.querySelectorAll(".config.filter .row.items .more").forEach(function(more) {
                    more.style.display = 'none';
                });
                document.querySelectorAll(".config.filter .row.items .show-more").forEach(function(item) {
                    item.classList.remove('open');
                });
                this.closest('.row.items').querySelector('.more').style.display = 'block';
                this.classList.add('open');
            }
        });
    });

    // document.getElementById("reset-button").addEventListener("click", function (event) {
    //     document.getElementById("reset").value = '1';
    //     document.getElementById("config-form").submit();
    // });

    if (document.querySelector(".wrapper.contact-form")) {
        if (document.querySelector(".wrapper.contact-form .contact-info")) {
            document.querySelector("#yform-contact-form-info textarea").value = document.querySelector(".wrapper.contact-form .contact-info").textContent;
        }
        if (document.querySelector(".wrapper.contact-form .contact-category")) {
            document.querySelector("#yform-contact-form-category input").value = document.querySelector(".wrapper.contact-form .contact-category").textContent;
        }
        if (document.querySelector(".wrapper.contact-form .contact-receiver")) {
            document.querySelector("#yform-contact-form-receiver input").value = document.querySelector(".wrapper.contact-form .contact-receiver").textContent;
        }
        document.querySelectorAll(".contact-link").forEach(function(link) {
            link.addEventListener("click", function(event) {
                event.preventDefault();
                var receiver = this.href.split("mailto:")[1];
                document.querySelector("#yform-contact-form-receiver input").value = receiver;
                var offset = document.getElementById("contact-form-wrapper").getBoundingClientRect().top + window.scrollY - 100;
                window.scrollTo({ top: offset, behavior: 'smooth' });
            });
        });
        document.querySelector(".wrapper.config").addEventListener("click", function(event) {
            if (event.target.matches("#request_config")) {
                event.preventDefault();
                document.querySelector("#yform-contact-form-info textarea").value = document.getElementById('userKonfiguration').value;
                document.querySelector("#yform-contact-form-info textarea").style.height = '120px';
                var offset = document.getElementById("contact-form-wrapper").getBoundingClientRect().top + window.scrollY - 100;
                window.scrollTo({ top: offset, behavior: 'smooth' });
            }
        });
        document.getElementById("contact-form-wrapper").addEventListener("click", function(event) {
            if (event.target.matches(".btn")) {
                event.preventDefault();
                var errors = false;
                document.querySelectorAll("#contact-form-wrapper form input").forEach(function(input) {
                    if (input.type === 'checkbox') {
                        input.parentElement.classList.remove('is-invalid');
                        if (input.required && input.offsetParent !== null && !input.checked) {
                            input.parentElement.classList.add('is-invalid');
                            errors = true;
                        }
                    } else {
                        input.classList.remove('is-invalid');
                        if (input.required && input.offsetParent !== null && input.value === '') {
                            input.classList.add('is-invalid');
                            errors = true;
                        }
                        if (input.type === 'email' && (input.value.indexOf('@') === -1 || input.value.indexOf('.') === -1)) {
                            input.classList.add('is-invalid');
                            errors = true;
                        }
                    }
                });
                document.querySelectorAll("#contact-form-wrapper form select").forEach(function(select) {
                    select.classList.remove('is-invalid');
                    if (select.required && select.offsetParent !== null && select.value === '') {
                        select.classList.add('is-invalid');
                        errors = true;
                    }
                });
                document.querySelectorAll("#contact-form-wrapper form textarea").forEach(function(textarea) {
                    textarea.classList.remove('is-invalid');
                    if (textarea.required && textarea.offsetParent !== null && textarea.value === '') {
                        textarea.classList.add('is-invalid');
                        errors = true;
                    }
                });
                if (!errors) {
                    var form = document.querySelector("#contact-form-wrapper form");
                    fetch(form.action, {
                        method: 'POST',
                        body: new URLSearchParams(new FormData(form))
                    })
                    .then(response => response.text())
                    .then(data => {
                        document.getElementById("contact-form-wrapper").innerHTML = data;
                        if (document.querySelector(".wrapper.contact-form .alert.alert-success")) {
                            window.location.href = document.querySelector(".wrapper.contact-form .contact-ty-page").textContent;
                        }
                    });
                }
                var offset = document.getElementById("contact-form-wrapper").getBoundingClientRect().top + window.scrollY - 100;
                window.scrollTo({ top: offset, behavior: 'smooth' });
            }
        });
        document.querySelector(".wrapper.contact-form .cf-country select").addEventListener("change", function() {
            if (this.value === 'NONE') {
                document.querySelector(".wrapper.contact-form .cf-country2").style.display = 'block';
            } else {
                document.querySelector(".wrapper.contact-form .cf-country2").style.display = 'none';
            }
        });
    }

    if (document.querySelector(".wrapper.gated-content")) {
        document.getElementById('yform-gated-content-form-gated_content').querySelector('input').value = document.querySelector(".wrapper.gated-content .gc-gated_content").textContent;
        document.getElementById('yform-gated-content-form-article_slice').querySelector('input').value = document.querySelector(".wrapper.gated-content .gc-slice").textContent;
        document.getElementById("gated-content-form-wrapper").addEventListener("click", function(event) {
            if (event.target.matches(".btn")) {
                event.preventDefault();
                var form = document.getElementById("gated-content-form-wrapper").querySelector("form");
                fetch(form.action, {
                    method: 'POST',
                    body: new URLSearchParams(new FormData(form))
                })
                .then(response => response.text())
                .then(data => {
                    document.getElementById("gated-content-form-wrapper").innerHTML = data;
                    var offset = document.getElementById("gated-content-form-wrapper").getBoundingClientRect().top + window.scrollY - 100;
                    window.scrollTo({ top: offset, behavior: 'smooth' });
                    if (document.querySelector(".wrapper.gated-content .alert.alert-success")) {
                        window.location.href = document.querySelector(".wrapper.gated-content .gc-ty-page").textContent;
                    }
                });
            }
        });
    }

    if (document.getElementById("service-form-send")) {
        fetch('/de/ajax/service-uploads/', {
            method: 'POST'
        });
    }
});

window.addEventListener("load", function() {
    if (document.querySelector(".wrapper.hero .h1")) {
        var backgroundHeight = document.querySelector(".wrapper.hero .background").offsetHeight;
        var containerPosition = document.querySelector(".wrapper.hero .container").getBoundingClientRect().top;
        var headlineHeight = document.querySelector(".wrapper.hero .h1").offsetHeight;
        var margin = (window.innerWidth < 992 && document.body.classList.contains('eddicy')) ? 
            backgroundHeight - containerPosition - headlineHeight - 160 : 
            backgroundHeight - containerPosition - headlineHeight - 80;
        document.querySelector('.wrapper.hero .background').style.marginBottom = '-' + margin + 'px';
    }
});

window.addEventListener("scroll", function() {
    var pos = window.scrollY;
    if (pos > 100) {
        document.body.classList.add('scroll');
    } else {
        document.body.classList.remove('scroll');
    }
});